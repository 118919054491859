import "./App.css";

import {
	HashRouter,
	Routes,
	Route,
	Navigate,
	BrowserRouter,
} from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Header from "./components/Header";
import Games from "./components/Games";
import Footer from "./components/Footer";
import RouterFunc from "./Router/RouterFunc";




function App() {
	return (
		<div className="App">
			<Header />


				<RouterFunc/>
			
			<Footer />
		</div>
	);
}

export default App;
