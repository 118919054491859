import React from "react";
import './Contact.css'
import Team from "./Templates/Team";
import { TeamData } from "../data/Team";
import { Container, DivSectiion } from "./Templates/TeamStyled";
import ContactImg from '../resources/contact/contact_image.png'
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn,  faYoutube } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
	return (
		<div className="contact-container">

			<div className="home-content-holderCOntact ">
				<div className="overlay"></div>
				<div className="contactFlex">
					<img src={ContactImg}/>
					<div className="Icon">
					<FontAwesomeIcon icon={faLocationDot} className="iconMain" />
						<h3>თბილისი, საქართველო</h3>
					</div>
					<div className="Icon">
					<FontAwesomeIcon icon={faEnvelope} className="iconMain" />
					<h3><a href="mailto:info@imaginary.ge">info@imaginary.ge </a></h3>
					</div>
					<div className="socials">
					<a target="_blank" href="https://www.linkedin.com/company/imaginary1/">
						<FontAwesomeIcon icon={faLinkedinIn} />
					</a>
					<a target="_blank" href="https://www.facebook.com/profile.php?id=61553242972295">
					<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a href="">
					<FontAwesomeIcon icon={faYoutube} />
					</a>
					</div>
				</div>
			</div>
			
		</div>
	)
};

export default Contact;
