import "./Home.css";
import React from "react";
import image from "../resources/Home.png";
import { ReactComponent as HomeSvgs } from "../resources/animation.svg";
import { Link } from "react-router-dom";


const Home = ({ navbarItems }) => {
	return (
		<div className="home-container">
			<div className="home-image-container">
				<img src={image} alt="My Image" className="home-image" />
			</div>
			<div className="home-content-holder">
				
				<div className="home-content">
					<div className="home-content-left">
						<div className="home-content-text-1">
							დაინახე, ითამაშე და ისწავლე Imagin<span>AR</span>y-თან ერთად
						</div>
						<div className="home-content-text-2">
							სამგანზომილებიანი, ინტერაქციული თამაშები სამეცნიერო საგნებში.
						</div>
					</div>
					<div className="home-content-right">
						<HomeSvgs />
					</div>
				</div>
				<Link to="/Games" style={{textDecoration:"none"}}>
				<div className="start-button">დაწყება</div>
				</Link>
			</div>
		</div>
	);
};

export default Home;
