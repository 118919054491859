import "./Header.css";
import React from "react";
import { ReactComponent as MySvg } from "../resources/ImaginaryLogo.svg";
import  MySvgmobile from "../resources/ImaginaryLogo.svg";
import { ReactComponent as Search } from "../resources/Search.svg";

import { ReactComponent as Switch } from "../resources/switch.svg";
import { ReactComponent as Language } from "../resources/Language.svg";
import { ReactComponent as Person } from "../resources/Person.svg";
import NavBar from "./NavBar";
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from "react-router-dom";
const Header = () => {
	const navbarItems = [
		{ text: "მთავარი", url: "/" },
		{ text: "თამაშები", url: "/Games" },
		{ text: "ჩვენ შესახებ", url: "/AboutUs" },
		{ text: "კონტაქტი", url: "/Contact" },
	];
	return (
		<header>

		
		<div className="header-container desktop">
			
			<MySvg className="header-svg" />
			<Search />

			<div className="header-right">
				<Person />
				<Language />
				<Switch />
			</div>
			
		</div>
		<div className="navBarContainer">
			<NavBar/>
		</div>
		<div className="mobile">
			<div >
		<Menu>
		
			{navbarItems.map((item, index) => {
				return (
					// add active to the selected next time
					<a href={item.url} className="navbar-item" key={index}>
						{item.text}
					</a>
				);
			})}
			
		</Menu>
		</div>
		<img src={MySvgmobile}/>
		{/* <MySvgmobile/> */}
		<div className="header-container">
			
			
			

			<div className="header-right">
			
				<Person />
				<Language />
				
			</div>
			
		</div>
		</div>
		</header>
	);
};

export default Header;
