export const TeamData=[
    {
        id:1,
        img:require('../resources/About/team/lasha.png'),
        Name:"ლაშა დალაქიშვილი",
        Position:"CEO OF IMAGINARY",
        linkdin:"https://www.linkedin.com/in/lasha-dalakishvili-0649b1159/"
    },
    {
        id:2,
        img:require('../resources/About/team/giorgi.png'),
        Name:"გიორგი თაყაძე",
        Position:"CTO AT IMAGINARY",
        linkdin:"https://www.linkedin.com/in/george-takadze-b0522a173/"
    },
    {
        id:3,
        img:require('../resources/About/team/luka.png'),
        Name:"ლუკა შავიშვილი",
        Position:"CFO AT IMAGINARY",
        linkdin:"https://www.linkedin.com/in/luka-shavishvili-7864b7227/"
    },

    {
        id:4,
        img:require('../resources/About/team/zuka.png'),
        Name:"ზუკა კენჭუაშვილი",
        Position:"Game DELEVOPER AT IMAGINARY",
        linkdin:"https://www.linkedin.com/in/zuka-kenchuashvili-71792a23a/",
    },
   
]