import React from "react";
import './About.css'
import Team from "./Templates/Team";
import { TeamData } from "../data/Team";
import { Container, DivSectiion } from "./Templates/TeamStyled";

const AboutUs = () => {
	return (
		<div className="About-container">

			<div className="home-content-holder About "></div>
			<div className="AboutUs ">
				<div>
					<h2>ჩვენ შესახებ</h2>
				</div>
				<div className="container">
					<div className="About__content ">

						<div className="left_size">
							<p>
								იმეჯინარის მთავარი მიზანია,
								სწავლება გახადოს ხელშესახები,
								სახალისო, მარტივი და ყველასთვის
								გასაგები. ამ ეტაპზე ჩვენ გთავაზობთ
								სამგანზომილებიან სამეცნიერო
								თამაშებს, თუმცა მომავალში ვაპირებთ
								აუგმენტურ რეალობაზე დაფუძნებული
								ვირტუალური ლაბორატორიის შექმნას.
							</p>
						</div>
						<div className="right_size">
						<iframe width="560" height="315" src="https://www.youtube.com/embed/a42vPfw5J2A?si=lYC866bpbyfJJdbL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						</div>
					</div>
					<p className="extra">თუ გექნებათ რაიმე სახის იდეა, შემოთავაზება ან უკუკავშირი ჩვენი სერვისის შესახებ, ნებისმიერ
						დროს დაგვიკავშირდით კონტაქტებში მითითებულ პლატფორმებზე.</p>
				</div>
			</div>

			<div className="Team">
				<Container>
					<DivSectiion>
				{
					TeamData.map((value)=>{
						return(
							<Team key={value.id} link={value.linkdin} imgSrc={value.img} Name={value.Name} Position={value.Position}/>	
						)
					})
				}
				</DivSectiion>
				</Container>
			</div>

			<div className="bannerFooter">

			</div>
		</div>
	)
};

export default AboutUs;
