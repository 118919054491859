export const Courses=[
    {
        id:1,
        img:require('../resources/Games/qimia.png'),
        title:"ქიმია",
        info:[
            {
                id:1,
                title:"კონცენტრაცია",
                address:'https://imaginary.ge/Game_2/index.html'
            }
        ]
    },
    {
        id:2,
        img:require('../resources/Games/fizika.png'),
        title:"ფიზიკა",
        info:[
            {
                id:1,
                title:"ბერკეტი",
                address:'https://imaginary.ge/Game/index.html'
            }
        ]
    },
    {
        id:3,
        img:require('../resources/Games/biologia.png'),
        title:"ბიოლოგია",
        info:[
            {
                id:1,
                title:"მალე დაემატება",
                address:'#'
            }
        ]
    },
    {
        id:3,
        img:require('../resources/Games/matematika.png'),
        title:"მათემატიკა",
        info:[
            {
                id:1,
                title:"მალე დაემატება",
                address:'#'
            }
        ]
    }
]