import React from "react";
import "./NavBar.css";
import { NavLink } from "react-router-dom";

const NavBar = ({ items }) => {
	const navbarItems = [
		{ text: "მთავარი", url: "/" },
		{ text: "თამაშები", url: "/Games" },
		{ text: "ჩვენ შესახებ", url: "/AboutUs" },
		{ text: "კონტაქტი", url: "/Contact" },
	];
	return (
		<>
		<div className="navbar-items">
			{navbarItems.map((item, index) => {
				return (
					// add active to the selected next time
					<NavLink to={item.url} className="navbar-item" key={index}>
						{item.text}
					</NavLink>
				);
			})}


		</div>


</>
	);
};

export default NavBar;
