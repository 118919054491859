import React from 'react'
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'

function Login() {
    return (
        <div className="contact-container">

            <div className="login-content-holderCOntact ">
                <div className='mainLogin'>

                    <div className='mainLogin_inner'>
                        <div className='innerMain'>
                            <div className='topBar'>
                            <FontAwesomeIcon icon={faCircleUser} />
                            <h3>შესვლა</h3>
                            <p>დარეგისტრირდი და მიიღეთ სიახლეებზე ინფორმაცია და დამატებითი შეთავაზებები</p>
                            </div>
                            <div className='authorized'>
                            <FontAwesomeIcon icon={faGoogle} />
                            <h5>Google-ით რეგისტრაცია</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login