import React from 'react'
import { CurrentDiv } from './TeamStyled'

function Team({imgSrc, Name, Position, link}) {
  return (
    <CurrentDiv className='teamClass'>
      <a href={link} className='linkTeam'>
        <img src={imgSrc}/>
        <h3>{Name}</h3>
        <h4>{Position}</h4>
        </a>
    </CurrentDiv>
  )
}

export default Team