import React from "react";
import NavBar from "./NavBar";
import './Games.css'
import gameImage from '../resources/Games/light 1.png'
import { Courses } from "../data/Courses";
const Games = ({ navbarItems }) => {
	return (
		<div className="Game-container">
			
			<div className="home-content-holder gameInner">
				<div className="image">
					<img src={gameImage}/>
				</div>
				
				
			</div>

			<div className="main_container">
				<div className="container">
				<div className="infos">
				
					{
						Courses.map((value)=>{
							return(
								<div className="box">
									<div className="image">
										<img src={value.img} alt="qimia"/>
									</div>
									{
										value.info?.map((element)=>{
											return(
												
												<div className="infoElement">
												<a target="_blank" href={element.address}>
													{element.title}
												</a>
												</div>
												
											)
										})
									}
								</div>
							)
						})
					}
					
				</div>
				</div>
			</div>
		</div>
	)
};

export default Games;
