import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "../components/AboutUs";
import Games from "../components/Games";
import Home from "../components/Home";
import Layout from "../components/Layout/Layout";
import Contact from "../components/Contact";
import Login from "../components/Login";


const RouterFunc = () => {
    return (
        <Routes>
            <Route path="/" element={<Home /> } />
            <Route path="/Games" element={<Layout><Games /> </Layout>} />
            <Route path="/AboutUs" element={<Layout><AboutUs /> </Layout>} />
            <Route path="/Contact" element={<Layout><Contact /> </Layout>} />
            <Route path="/login" element={<Layout><Login /> </Layout>} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}
export default RouterFunc