import "./Footer.css";
import React from "react";
import  MySvgmobile from "../resources/ImaginaryLogo.svg";
import { ReactComponent as Mail } from "../resources/Mail.svg";
import { ReactComponent as PinPoint } from "../resources/PinPoint.svg";
import { ReactComponent as Share } from "../resources/share.svg";
import { ReactComponent as Facebook } from "../resources/Facebook.svg";
import { ReactComponent as LinkdIn } from "../resources/LinkdIn.svg";
import { ReactComponent as Youtube } from "../resources/Youtube.svg";

const Footer = () => {
	const share_icon = (Icon) => {
		return <Icon />;
	};

	const info = (Com, text) => {
		return (
			<div className="footer-mid-info">
				<Com />
				<div className="footer-mid-info-text">{text}</div>
			</div>
		);
	};

	return (
		<div className="footer-container">
				<img src={MySvgmobile}/>
			<div className="footer-mid">
				{info(PinPoint, "საქართველო, თბილისი")}
				{info(Mail, "info@imaginary.ge")}
			</div>
			<div className="footer-right">
					
				<a target="_blank" href="https://www.linkedin.com/company/imaginary1/">
				{share_icon(LinkdIn)}
				</a>
				<a target="_blank" href="https://www.facebook.com/profile.php?id=61553242972295">
				{share_icon(Facebook)}
				</a>
				{share_icon(Youtube)}
			</div>
		</div>
	);
};

export default Footer;
