import Styled from "styled-components";



export const Container=Styled.div`
    max-width:1240px;
    margin:auto;
`



export const DivSectiion=Styled.section`
   display:flex;
   flex-wrap:wrap;
   justify-content:space-evenly;
   gap:30px;

`


export const CurrentDiv=Styled.div`
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   width:425px;
   background: rgb(176,18,187);
   background: linear-gradient(180deg, rgba(176,18,187,1) 0%, rgba(0,56,108,1) 100%);
   border:6px solid #B012BB;
   padding:50px;
   gap:10px;
   text-align:center;
`
